<template>
  <div class="tenancy-agreement-general">
    <fd-form ref="taGeneralForm" @submit="submitTA">
      <div
        class="sticky-element col-12 row justify-content-between align-items-center p-2 mb-4"
      >
        <h5>Tenancy Agreement Info</h5>
      </div>
      <fd-form-section title="Contract Detail">
        <fd-date-picker
          v-model="tenancyAgreement.agreementDate"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Agreement Date"
          name="propertyAddress"
          :validators="[]"
        ></fd-date-picker>
        <fd-select
          v-model="tenancyAgreement.terms"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Fixed Term"
          name="terms"
          :options="['1', '2', '3']"
          :optionLabel="(val) => `${val} Years`"
          :optionValue="(val) => val"
          :validators="[...getIsFieldRequired()]"
        ></fd-select>
        <fd-date-picker
          v-model="tenancyAgreement.commencementDate"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Commencement Date"
          name="commencementDate"
          :validators="[...getIsFieldRequired()]"
        ></fd-date-picker>
        <fd-date-picker
          v-model="taDateOfExpiry"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Date of Expire"
          name="dateOfExpiry"
          readonly
          :validators="[...getIsFieldRequired()]"
        ></fd-date-picker>
        <div class="col-12 row">
          <fd-date-picker
            v-model="tenancyAgreement.rentFreePeriodFrom"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Rent Free Period granted (From)"
            name="rentFreePeriodFrom"
            :validators="rentFreeValidation"
          ></fd-date-picker>
          <fd-date-picker
            v-model="tenancyAgreement.rentFreePeriodTo"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Rent Free Period granted (To)"
            name="rentFreePeriodTo"
            :validators="rentFreeValidation"
          ></fd-date-picker>
        </div>
        <fd-input
          v-model="tenancyAgreement.monthlyRent"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Monthly Rent"
          name="monthlyRent"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <fd-input
          v-model="tenancyAgreement.securityDeposit"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Security Deposit"
          name="securityDeposit"
          type="number"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <fd-input
          v-model="tenancyAgreement.utilitiesDeposit"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Utility Deposit"
          name="utilitiesDeposit"
          type="number"
          :validators="[]"
        ></fd-input>
        <div class="col-12 row px-1 mb-2">
          <fd-input
            v-model="tenancyAgreement.otherDeposit"
            class="col-12 sm-col-6 md-col-4 xl-col-3 sm-pr-1 mb-1"
            label="Other Deposit"
            name="otherDeposit"
            type="number"
            :validators="[]"
          ></fd-input>
          <fd-input
            v-model="tenancyAgreement.remark"
            class="col-12 sm-col-6 md-col-4 xl-col-3 sm-pl-1 mb-1"
            label="Other Deposit's Remark"
            name="otherDeposit"
            :validators="otherDepositValidation"
          ></fd-input>
        </div>
        <fd-input
          v-if="showTax"
          v-model="tenancyAgreement.taxRate"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Tax Rate"
          name="taxRate"
          type="number"
          :validators="[]"
        ></fd-input>
        <fd-input
          v-if="showTax"
          v-model="tenancyAgreement.taxAmount"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Tax Chargeable"
          name="taxAmount"
          type="number"
          :validators="[]"
        ></fd-input>
        <div class="col-12 row px-1 mb-2">
          <fd-select
            v-model="taPurpose"
            class="col-12 sm-col-6 md-col-3 xl-col-3 mb-1"
            label="Purpose"
            name="purpose"
            :options="rentPurposeOptions"
            :optionLabel="(val) => val"
            :optionValue="(val) => val"
            :validators="[...getIsFieldRequired()]"
          ></fd-select>
          <fd-input
            v-if="taPurpose === rentPurpose.OTHER"
            v-model="taOtherPurpose"
            class="col-12 sm-col-6 md-col-4 xl-col-3 mb-2"
            label="Other Purpose"
            name="purpose"
            placeholder="Please provide a purpose"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
        </div>
        <div class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2 row">
          <label class="label col-12">Further Term</label>
          <div class="col-12 d-flex align-items-center">
            <fd-select
              v-model="tenancyAgreement.furtherTerm"
              class="col-6"
              name="furtherTerm"
              :options="['1', '2', '3']"
              :optionLabel="(val) => `${val} Years`"
              :optionValue="(val) => val"
              :validators="[...getIsFieldRequired()]"
            ></fd-select>
            <span class="px-2">at</span>
            <fd-input
              v-model="tenancyAgreement.rentalFurtherTerm"
              class="col-6"
              name="rentalFurtherTerm"
              placeholder="Market Rate / %"
              :validators="[...getIsFieldRequired()]"
            ></fd-input>
          </div>
        </div>
      </fd-form-section>

      <fd-form-section title="Offer to Lease Document">
        <fd-select
          v-model="tenancyAgreement.offerToLeaseType"
          class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
          label="Type"
          name="otlType"
          :options="otlTypeOptions"
          :optionKey="(val) => val.id"
          :optionValue="(val) => val.name"
          :validators="[...getIsFieldRequired()]"
        ></fd-select>
        <fd-textarea
          v-model="tenancyAgreement.specialConditions"
          class="col-12 px-1 mb-2"
          label="Special Condition"
          name="specialConditions"
          :validators="[]"
        ></fd-textarea>
        <file-uploader
          v-model="tenancyAgreement.offerToLeaseDocuments"
          class="col-12 px-1 mb-2"
          label="Document"
          :maxSize="15"
          :maxFile="20"
          multiple
          :allowed="[
            'pdf',
            'doc',
            'docx',
            'odt',
            'xls',
            'xlsx',
            'ods',
            'ppt',
            'pptx',
            'jpg',
            'png',
            'jpeg'
          ]"
          accept=".pdf, .doc, .docx, .odt, .xls, .xlsx, .ods, .ppt, .pptx, .jpg, .png, .jpeg"
          @isLoading="setIsLoading"
        ></file-uploader>
      </fd-form-section>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  minValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { offerToLeaseType, rentPurpose } from "@/enums";

export default {
  components: {
    FileUploader: () => import("@/components/GlobalComponents/FileUploader")
  },
  mixins: [],
  props: {
    required: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    generalData: {
      type: Object
    }
  },
  data: function () {
    return {
      tenancyAgreement: {
        agreementDate: "",
        terms: "",
        commencementDate: "",
        dateOfExpiry: "",
        rentFreePeriodFrom: "",
        rentFreePeriodTo: "",
        // Money Related
        monthlyRent: "",
        securityDeposit: "",
        utilitiesDeposit: "",
        otherDeposit: "",
        remark: "",
        taxRate: "",
        taxAmount: "",
        //
        purpose: "",
        furtherTerm: "",
        rentalFurtherTerm: "",
        offerToLeaseType: "",
        offerToLeaseDocuments: [],
        specialConditions: ""
      },

      taPurpose: "",
      taOtherPurpose: "",

      otlTypeOptions: this.$mapJsonToArray(offerToLeaseType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      rentPurposeOptions: this.$mapJsonToArray(rentPurpose, (e) => e),

      rentPurpose,
      minValue
    };
  },
  computed: {
    showTax() {
      return (
        this.tenancyAgreement.offerToLeaseType === offerToLeaseType.RDF ||
        this.tenancyAgreement.offerToLeaseType === offerToLeaseType.RDNF
      );
    },
    taDateOfExpiry() {
      let val = this.$dayjs(
        this.tenancyAgreement.commencementDate,
        "YYYY-MM-DD"
      )
        .add(this.tenancyAgreement.terms, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      return val;
    },
    rentFreeValidation() {
      return this.tenancyAgreement.rentFreePeriodFrom ||
        this.tenancyAgreement.rentFreePeriodTo
        ? [required]
        : [];
    },
    otherDepositValidation() {
      return this.tenancyAgreement.otherDeposit ? [required] : [];
    }
  },
  watch: {
    tenancyAgreement: {
      handler(val) {
        this.$emit("update:generalData", val);
      },
      deep: true
    },
    taDateOfExpiry: {
      handler(val) {
        this.tenancyAgreement.dateOfExpiry = val;
      }
    },
    taPurpose: {
      immediate: true,
      handler(val) {
        if (this.taPurpose !== rentPurpose.OTHER) {
          this.tenancyAgreement.purpose = val;
        }
      }
    },
    taOtherPurpose: {
      immediate: true,
      handler(val) {
        if (this.taPurpose === rentPurpose.OTHER) {
          this.tenancyAgreement.purpose = val;
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit && this.generalData) {
      this.tenancyAgreement = this._.cloneDeep(this.generalData);
      this.initPurpose();
    }
  },
  methods: {
    getIsFieldRequired() {
      return this.required ? [required] : [];
    },
    initPurpose() {
      if (this.rentPurposeOptions.includes(this.tenancyAgreement.purpose)) {
        this.taPurpose = this.tenancyAgreement.purpose;
      } else {
        this.taPurpose = rentPurpose.OTHER;
        this.taOtherPurpose = this.tenancyAgreement.purpose;
      }
    },
    setIsLoading(bool) {
      this.$emit("update:isLoading", bool);
    },
    checkValidation() {
      return this.$refs.taGeneralForm.checkValidation();
    },
    submitTA() {
      this.$emit("update", this.tenancyAgreement);
    }
  }
};
</script>

<style lang="scss"></style>
